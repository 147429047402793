:root {
  --primary-color: #ed008c; // Default color
  --secondary-color: #ffd3ed; // Default color
}

body {
  background-color: #fff5fb;
}

//no pull to refresh in chrome(mobile)
html,
body {
  overscroll-behavior-y: none;
}

@media screen and (max-width: 768px) {
  .th-95-height-scroll {
    height: 100vh !important;
    overflow-y: scroll;
  }
}

.slick-dots li button:before {
  font-size: 10px;
  line-height: 20px;
}

.slick-dots li.slick-active button:before {
  color: #2194ff !important;
  font-size: 10px;
  line-height: 20px;
  /* font-size: 14px; */
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

a {
  color: initial;
  text-decoration: none !important;
}

.th-bg-grey {
  background-color: #f6f6f6 !important;
}

.th-bg-primary {
  background-color: var(--primary-color) !important;
}
.th-bg-primary-1 {
  background-color: #ffe0f2 !important;
}

.th-bg-secondary {
  background: var(--secondary-color);
}

.th-bg-white {
  background-color: #ffffff !important;
}

.th-primary {
  color: var(--primary-color) !important;
}

.th-btn-disabled {
  background-color: var(--secondary-color);
}

.th-white {
  color: #ffffff !important;
}

.th-btn-primary {
  background-color: var(--primary-color);
  color: #ffffff;
}

.th-border-primary {
  border: 1px solid var(--primary-color);
}

.th-border-secondary {
  border: 1px solid #c2c1c2;
}

.th-btn-secondary {
  background-color: #ffffff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
}

.th-form {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: fixed;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  .th-form {
    width: 100%;
  }
}

.eye {
  position: absolute;
  right: 7%;
  z-index: 1;
  top: 45%;
}

.th-form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(73, 80, 87);
  background-color: rgb(243, 243, 243);
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  margin-top: 10px;
}

input:focus-visible {
  outline: none !important;
}

.th-form-control-sm {
  height: 30px !important;
  font-size: 0.9rem !important;
}
.th-tabs .css-19kzrtu {
  padding: 6px !important;
}
.th-tabs .css-1aquho2-MuiTabs-indicator {
  background-color: var(--primary-color) !important;
}

.th-tabs .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primary-color) !important;
}

.th-custom-icon .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: var(--primary-color) !important;
}

.th-custom-icon .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: var(--primary-color) !important;
}
.th-txt-truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.th-txt-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.th-txt-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
